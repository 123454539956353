import React from 'react'
import PropTypes from 'prop-types'
import Img from '../Img/Img'
import styles from './SliderNavImage.module.scss'

const SliderNavImage = ({ image }) => {
  return (
    <div>
      <div className={styles.wrap}>
        <Img data={image} className='object-contain' />
      </div>
    </div>
  )
}

SliderNavImage.propTypes = {
  image: PropTypes.object.isRequired,
}

export default SliderNavImage
