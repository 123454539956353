import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

const classes = {
  container: [
    'absolute',
    'inset-0',
    'flex',
    'items-center',
    'justify-center',
  ].join(' '),
  label: [
    'absolute',
    'top-0',
    'right-0',
    'left-0',
    'pt-1',
    'text-center',
    'font-sans-display',
    'text-sm',
    'text-gray-400',
    //
  ].join(' '),
  title: [
    'font-serif-display',
    'text-center',
    'text-2xl',
    'uppercase',
    'tracking-tight',
    'leading-tight',
  ].join(' '),
}

const SliderNavCover = ({ title, label }) => {
  return (
    <div className={classes.container}>
      {label && (
        <div className={classes.label}>
          <Translate id={label} />
        </div>
      )}
      <div className={classes.title}>{title}</div>
    </div>
  )
}

SliderNavCover.propTypes = {
  title: PropTypes.string.isRequired,
  /** `id` for <Translation /> */
  label: PropTypes.string,
}

export default SliderNavCover
