import React from 'react'
import PropTypes from 'prop-types'
import styles from './SliderNavItem.module.scss'

const SliderNavItem = ({
  swiperInstance,
  index,
  indexText,
  children,
  active,
}) => {
  return (
    <>
      <div className={`${styles.item} bg-gray-100`}>
        <div className={styles.children}>{children}</div>
        <div
          role='button'
          onClick={() => swiperInstance.slideTo(index)}
          className={styles.button}
        />
        <div className={styles.sizer} />
      </div>
      {indexText > 0 && (
        <button
          type='button'
          onClick={() => swiperInstance.slideTo(index)}
          className={`text-gray-100 border-b border-transparent ${
            active ? 'border-gray-100' : ''
          }`}
        >
          <span>{indexText}</span>
        </button>
      )}
    </>
  )
}

SliderNavItem.propTypes = {
  swiperInstance: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  indexText: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
}

export default SliderNavItem
