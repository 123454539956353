import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import styles from './SliderNav.module.scss'
// import CloseSliderNav from '../CloseSliderNav/CloseSliderNav'
import { Mousewheel } from 'swiper/dist/js/swiper.esm.js'
import SliderNavIcon from '../SliderNavIcon/SliderNavIcon'
import SmallZoomInIcon from '../SmallZoomInIcon/SmallZoomInIcon'

const swiperParams = {
  modules: [Mousewheel],
  slidesPerView: 'auto',
  spaceBetween: 10,
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  freeMode: true,
  speed: 600,
  mousewheel: true,
}

const classes = {
  buttons: [
    'flex',
    'absolute',
    'right-0',
    'bottom-0',
    'left-0',
    'mr-auto',
    'ml-auto',
    'mb-6',
  ].join(' '),
  overlayButton: ['absolute', 'inset-0'].join(' '),
  nav: ['bg-gray-300', 'pt-6', 'pb-6'].join(' '),
  hide: ['absolute', 'top-0', 'right-0', 'mr-6', 'z-10'].join(' '),
}

class SliderNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navVisible: false,
    }
    this.showNav = this.showNav.bind(this)
    this.hideNav = this.hideNav.bind(this)
    this.handleSlideChange = this.handleSlideChange.bind(this)
  }

  componentDidMount() {
    const { swiperInstance } = this.props
    if (swiperInstance && swiperInstance.destroyed) return
    swiperInstance.on('slideChange', this.handleSlideChange)
  }

  componentWillUnmount() {
    const { swiperInstance } = this.props
    swiperInstance.off('slideChange', this.handleSlideChange)
  }

  handleSlideChange() {
    if (this.state.navVisible) {
      this.hideNav()
    }
  }

  showNav() {
    this.setState({ navVisible: true })
  }

  hideNav() {
    this.setState({ navVisible: false })
  }

  render() {
    const { children, showButton, onZoomInClick } = this.props
    const { navVisible } = this.state
    return (
      <>
        <div
          className={[
            classes.buttons,
            styles.buttons,
            showButton ? styles.visible : '',
          ].join(' ')}
        >
          <button
            type='button'
            className='focus:outline-none'
            onClick={this.showNav}
          >
            <SliderNavIcon />
          </button>
          <button
            type='button'
            className='ml-3 focus:outline-none'
            onClick={onZoomInClick}
          >
            <SmallZoomInIcon />
          </button>
        </div>
        {showButton && navVisible && (
          <div
            className={[classes.overlayButton, styles.overlayButton].join(' ')}
          >
            <button
              type='button'
              className='block w-full h-full focus:outline-none'
              onClick={this.hideNav}
            />
          </div>
        )}
        <div
          className={[
            styles.nav,
            classes.nav,
            navVisible && showButton ? styles.visible : '',
          ].join(' ')}
        >
          <Swiper {...swiperParams}>{children}</Swiper>
          {/* <div
            className={[
              classes.hide,
              styles.hide,
              navVisible && showButton ? styles.visible : '',
            ].join(' ')}
          >
            <CloseSliderNav onClick={this.hideNav} />
          </div> */}
        </div>
      </>
    )
  }
}

SliderNav.propTypes = {
  children: PropTypes.node.isRequired,
  swiperInstance: PropTypes.object.isRequired,
  showButton: PropTypes.bool,
  onZoomInClick: PropTypes.func,
}

export default SliderNav
