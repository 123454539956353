import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { watchViewport, unwatchViewport } from 'tornis'
import CloseMainNav from '../CloseMainNav/CloseMainNav'
import styles from './ProjectImageDescription.module.scss'
import Portal from '../Portal/Portal'
import { Translate } from 'react-localize-redux'

const colors = {
  brown: 'bg-brown-300 text-gold-100',
  gold: 'bg-gold-100 text-brown-200',
  gray: 'bg-gray-200 text-brown-100',
  grayDark: 'bg-gray-300 text-brown-200',
}

const classes = {
  button: [
    'absolute',
    'top-0',
    'bottom-0',
    'left-0',
    'flex',
    'items-center',
    'justify-center',
  ].join(' '),
  arrow: [
    'mr-3',
    'font-serif-display',
    'text-center',
    'text-xl',
    //
  ].join(' '),
  label: [
    'uppercase',
    'text-xl',
    //
  ].join(' '),
  description: [
    'absolute',
    'top-0',
    'bottom-0',
    'left-0',
    //
  ].join(' '),
  content: [
    'mt-32',
    'font-sans-display',
    'text-2xl',
    'lg:text-3xl',
    'leading-tight',
    //
  ].join(' '),
  featuredSerif: [
    'leading-none',
    'font-serif-display',
    'text-4xl',
    //
  ].join(' '),
  featuredSans: [
    'leading-none',
    'font-sans-display',
    'font-light',
    'text-4xl',
  ].join(' '),
  close: [
    'absolute',
    'top-0',
    'right-0',
    'mt-6',
    'mr-6',
    //
  ].join(' '),
  overlay: [
    'absolute',
    'inset-0',
    //
  ].join(' '),
}

class ProjectImageDescription extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      height: 'auto',
    }
    this.toggleDescription = this.toggleDescription.bind(this)
    this.setSize = this.setSize.bind(this)
  }

  componentDidMount() {
    watchViewport(this.setSize)
  }

  componentWillUnmount() {
    unwatchViewport(this.setSize)
  }

  setSize({ size }) {
    if (size.changed) {
      const { y } = size
      this.setState({ height: `${y}px` })
    }
  }

  toggleDescription() {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    const { buttonText, html, theme, active } = this.props
    const { visible, height } = this.state
    const processedHtml = html.replace('destacat-serif', classes.featuredSerif)
    return (
      <>
        <div
          role='button'
          className={[
            classes.button,
            styles.button,
            colors[theme],
            active ? styles.visible : '',
          ].join(' ')}
          onClick={this.toggleDescription}
        >
          <div className={[styles.label, classes.label].join(' ')}>
            <span className={classes.arrow}>↓</span>
            <span>
              {buttonText ? buttonText : <Translate id='description' />}
            </span>
          </div>
        </div>
        <Portal>
          <>
            <div
              className={[
                styles.description,
                classes.description,
                colors[theme],
                visible ? styles.visible : '',
              ].join(' ')}
              style={{ height }}
            >
              <div
                className={[classes.content, styles.content].join(' ')}
                dangerouslySetInnerHTML={{ __html: processedHtml }}
              />
              <div className={classes.close}>
                <CloseMainNav
                  color='#C9B480'
                  onClick={this.toggleDescription}
                />
              </div>
            </div>
            {visible && (
              <div
                role='button'
                className={classes.overlay}
                onClick={this.toggleDescription}
                style={{ zIndex: 99998 }}
              />
            )}
          </>
        </Portal>
      </>
    )
  }
}

ProjectImageDescription.propTypes = {
  buttonText: PropTypes.string,
  html: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
}

export default ProjectImageDescription
