import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import { getViewportState } from 'tornis'
import { PhotoSwipe } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import screenfull from 'screenfull'
import { coverThemeNames } from '../constants/coverThemeNames'
import { setHeaderTheme } from '../actions/headerTheme'
import { slideHasDescription } from '../actions/slideHasDescription'
import onPageRender from '../hocs/onPageRender'
import { createProjectsData } from './ProjectsPage'
import Slider from '../components/Slider/Slider'
import Slide from '../components/Slide/Slide'
import ProjectCover from '../components/ProjectCover/ProjectCover'
import ProjectSingleImage from '../components/ProjectSingleImage/ProjectSingleImage'
import SliderNav from '../components/SliderNav/SliderNav'
import SliderNavItem from '../components/SliderNavItem/SliderNavItem'
import SliderNavCover from '../components/SliderNavCover/SliderNavCover'
import SliderNavImage from '../components/SliderNavImage/SliderNavImage'
import SliderPrevNext from '../components/SliderPrevNext/SliderPrevNext'
import Portal from '../components/Portal/Portal'
import { Tablet, Mobile } from '../components/MediaQueries/MediaQueries'
import ProjectCoverMobile from '../components/ProjectCoverMobile/ProjectCoverMobile'
import StandaloneImage from '../components/StandaloneImage/StandaloneImage'
import Head from '../components/Head/Head'

/**
 * @param {Array} categories
 */
function allProjectsInCategories(categories) {
  let allIds = []
  let byId = {}

  categories.forEach(({ category }) => {
    const { document } = category
    const { data } = document
    const { title, projects, image, cover_theme } = data
    projects.forEach(({ project_link }, i) => {
      const { document } = project_link
      const { id, prismicId, fields } = document
      const { pagePath } = fields

      if (allIds.indexOf(id) > -1) return

      allIds.push(id)

      byId[id] = {
        data: { ...document.data },
        id,
        prismicId,
        pagePath,
        categoryName: title.text,
        categoryImage: image,
        coverTheme: cover_theme,
        firstInCategory: i === 0,
      }
    })
  })

  return { allIds, byId }
}

/**
 * @param {Object} projectsInCategories
 * @param {String} id
 */
function getPrevProject(projectsInCategories, id) {
  const { allIds, byId } = projectsInCategories
  const currIndex = allIds.indexOf(id)
  const prevIndex = currIndex === 0 ? null : currIndex - 1
  return prevIndex !== null ? byId[allIds[prevIndex]] : null
}

/**
 * @param {Object} projectsInCategories
 * @param {String} id
 */
function getNextProject(projectsInCategories, id) {
  const { allIds, byId } = projectsInCategories
  const currIndex = allIds.indexOf(id)
  const nextIndex = currIndex < allIds.length - 1 ? currIndex + 1 : 0
  const nextId = allIds[nextIndex]
  return byId[nextId]
}

/**
 * @param {Object} projectsInCategories
 * @param {String} id
 */
function getCategoryData(projectsInCategories, id) {
  const { byId } = projectsInCategories
  const { categoryName, categoryImage, coverTheme } = byId[id]
  return { categoryName, categoryImage, coverTheme }
}

/**
 * @param {Array} featuredPrismicIds
 * @param {Object} projectData
 */
function getFeaturedIdsArr(featuredPrismicIds, projectData) {
  const { allIds, byId } = projectData
  return featuredPrismicIds.map(prismicId => {
    return allIds.find(id => byId[id].prismicId === prismicId)
  })
}

/**
 * @param {Array} featuredPrismicIds
 * @param {Object} projectsData
 * @param {String} id
 */
function getPrevFeaturedProject(featuredPrismicIds, projectsData, id) {
  const { byId } = projectsData
  const featuredIds = getFeaturedIdsArr(featuredPrismicIds, projectsData)
  const currIndex = featuredIds.indexOf(id)
  const prevIndex = currIndex === 0 ? null : currIndex - 1
  return byId[featuredIds[prevIndex]]
}

/**
 * @param {Array} featuredPrismicIds
 * @param {Object} projectsData
 * @param {String} id
 */
function getNextFeaturedProject(featuredPrismicIds, projectsData, id) {
  const { byId } = projectsData
  const featuredIds = getFeaturedIdsArr(featuredPrismicIds, projectsData)
  const currIndex = featuredIds.indexOf(id)
  const nextIndex = currIndex < featuredIds.length - 1 ? currIndex + 1 : 0
  const nextId = featuredIds[nextIndex]
  return byId[nextId]
}

class Project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
      pswpOpen: false,
      pswpIndex: 0,
    }
    this.getSwiper = this.getSwiper.bind(this)
    this.showPswp = this.showPswp.bind(this)
    this.hidePswp = this.hidePswp.bind(this)
    this.watchHeaderStyle = this.watchHeaderStyle.bind(this)
    this.slidePrev = this.slidePrev.bind(this)
    this.slideNext = this.slideNext.bind(this)
    this.onZoomInClick = this.onZoomInClick.bind(this)
  }

  showPswp(pswpIndex) {
    if (screenfull.enabled) {
      screenfull.request().then(() => {
        this.setState({ pswpIndex, pswpOpen: true })
      })
    }
  }

  hidePswp() {
    this.setState({ pswpOpen: false })
  }

  getSwiper(swiper) {
    this.swiper = swiper
    this.setFirstSlide()
    this.setActiveSlide()
  }

  setFirstSlide() {
    if (!this.swiper) return

    const { location } = this.props

    if (location.state && location.state.skipCover) {
      this.swiper.slideTo(1, 0)
      this.setState({ activeSlide: 1 })
      setTimeout(() => {
        this.watchHeaderStyle()
        this.watchActiveSlide()
      }, 600)
    }
  }

  setActiveSlide() {
    if (!this.swiper) return

    this.watchActiveSlide()
    this.watchHeaderStyle()

    this.swiper.on('slideChange', () => {
      setTimeout(() => {
        this.setState({ activeSlide: this.swiper.activeIndex })
        this.watchHeaderStyle()
        this.watchActiveSlide()
      }, 600)
    })
  }

  watchHeaderStyle() {
    const { activeIndex } = this.swiper
    const { setHeaderTheme, data, location } = this.props
    const { state } = location
    let atHomepage = false
    if (state && state.atHomepage) atHomepage = true

    const { size } = getViewportState()
    const { x } = size

    if (x < 1024) {
      setHeaderTheme('gray')
      return
    }

    const { id } = data.project
    const { categories, projects } = data.projectsPage.data
    const projectsData = createProjectsData(projects, categories)
    const projectsInCategories = allProjectsInCategories(categories)
    const categoryData = getCategoryData(projectsInCategories, id)
    const { coverTheme } = categoryData
    const themeName = coverThemeNames[coverTheme]

    let nextProject
    if (atHomepage) {
      const { featuredPrismicIds } = data.homepage.fields
      nextProject = getNextFeaturedProject(featuredPrismicIds, projectsData, id)
    } else {
      nextProject = getNextProject(projectsData, id)
    }

    if (activeIndex === 0) {
      setHeaderTheme(themeName)
    } else if (activeIndex === data.project.data.body.length + 1) {
      setHeaderTheme(nextProject.theme)
    } else {
      setHeaderTheme('gray')
    }
  }

  watchActiveSlide() {
    const { activeSlide } = this.state
    const { data, slideHasDescription } = this.props
    const { body } = data.project.data
    const firstSlide = activeSlide === 0
    const lastSlide = activeSlide === body.length + 1

    if (firstSlide || lastSlide) {
      slideHasDescription(false)
      return
    }

    const bodyIndex = activeSlide - 1
    const currSlide = body[bodyIndex]
    const { primary } = currSlide
    const { description } = primary
    const hasDescription = description && description.html !== ''
    slideHasDescription(hasDescription)
  }

  slidePrev() {
    this.swiper.slidePrev()
  }

  slideNext() {
    this.swiper.slideNext()
  }

  onZoomInClick() {
    const { activeSlide } = this.state
    this.showPswp(activeSlide - 1)
  }

  render() {
    const { data, location } = this.props
    const { state } = location
    let atHomepage = false
    if (state && state.atHomepage) atHomepage = true
    const { activeSlide, pswpOpen, pswpIndex } = this.state
    const projectData = data.project.data
    const { fields } = data.project
    const { pagePath } = fields
    const { categories, projects } = data.projectsPage.data
    const { id } = data.project
    const { title, awards, intro, description, cover_photo, body } = projectData
    const projectsData = createProjectsData(projects, categories)
    const projectsInCategories = allProjectsInCategories(categories)
    const categoryData = getCategoryData(projectsInCategories, id)
    const { coverTheme } = categoryData
    const showSliderNav = activeSlide >= 1 && activeSlide < body.length + 1
    const pswpData = body.map(({ primary }, index) => {
      const { image } = primary
      const { url, dimensions } = image
      const { width, height } = dimensions
      return {
        index,
        src: url,
        w: width,
        h: height,
      }
    })

    let prevProject = null
    let nextProject = null

    if (atHomepage) {
      const { featuredPrismicIds } = data.homepage.fields
      prevProject = getPrevFeaturedProject(featuredPrismicIds, projectsData, id)
      nextProject = getNextFeaturedProject(featuredPrismicIds, projectsData, id)
    } else {
      prevProject = getPrevProject(projectsData, id)
      nextProject = getNextProject(projectsData, id)
    }

    return (
      <div className='overflow-hidden'>
        <Head
          title={title.text}
          path={pagePath}
          image={cover_photo}
          description={[intro.html, description.html].join(' ')}
        />
        <Tablet>
          <Slider getSwiper={this.getSwiper}>
            <div>
              <Slide>
                <ProjectCover
                  theme={coverThemeNames[coverTheme]}
                  title={title.text}
                  awards={awards.html}
                  intro={intro.html}
                  description={description.html}
                  image={cover_photo}
                  onClickNext={this.slideNext}
                  prevPath={prevProject && prevProject.fields.pagePath}
                  atHomepage={atHomepage}
                />
              </Slide>
            </div>
            {body.map((slice, i) => {
              const { sliceType } = slice

              if (sliceType === 'foto') {
                const { id, primary } = slice
                const {
                  image,
                  caption,
                  descriptionButton,
                  description,
                } = primary
                const index = i + 1
                const active = activeSlide === index
                return (
                  <div key={id}>
                    <Slide>
                      <ProjectSingleImage
                        image={image}
                        caption={caption.html}
                        descriptionButton={descriptionButton}
                        description={description.html}
                        theme={coverThemeNames[coverTheme]}
                        active={active}
                        onClick={() => this.showPswp(i)}
                        onClickPrev={this.slidePrev}
                        onClickNext={this.slideNext}
                        id={`projectSingleImage${i}`}
                      />
                    </Slide>
                  </div>
                )
              } else {
                return null
              }
            })}
            <div>
              <Slide>
                <ProjectCover
                  theme={nextProject.theme}
                  title={nextProject.data.title.text}
                  awards={
                    nextProject.data.awards
                      ? nextProject.data.awards.html
                      : null
                  }
                  intro={
                    nextProject.data.intro ? nextProject.data.intro.html : null
                  }
                  description={
                    nextProject.data.description
                      ? nextProject.data.description.html
                      : null
                  }
                  image={nextProject.data.cover_photo}
                  path={nextProject.fields.pagePath}
                  onClickPrev={this.slidePrev}
                  atHomepage={atHomepage}
                />
              </Slide>
            </div>
          </Slider>
          {this.swiper && (
            <SliderPrevNext
              visible={showSliderNav}
              swiperInstance={this.swiper}
              total={body.length}
              activeIndex={activeSlide}
            />
          )}
          {this.swiper && (
            <SliderNav
              showButton={showSliderNav}
              onZoomInClick={this.onZoomInClick}
              swiperInstance={this.swiper}
            >
              <div>
                <SliderNavItem
                  index={0}
                  indexText={0}
                  swiperInstance={this.swiper}
                >
                  <SliderNavCover title={title.text} />
                </SliderNavItem>
              </div>
              {body.map((slice, i) => {
                const { sliceType } = slice
                if (sliceType === 'foto') {
                  const { id, primary } = slice
                  const { image } = primary
                  const index = i + 1
                  const active = activeSlide === index
                  return (
                    <div key={id}>
                      <SliderNavItem
                        index={index}
                        indexText={index}
                        active={active}
                        swiperInstance={this.swiper}
                      >
                        <SliderNavImage image={image} />
                      </SliderNavItem>
                    </div>
                  )
                } else {
                  return null
                }
              })}
              <div>
                <SliderNavItem
                  index={body.length + 2}
                  indexText={body.length + 2}
                  swiperInstance={this.swiper}
                >
                  <SliderNavCover
                    title={nextProject.data.title.text}
                    label='nextProject'
                  />
                </SliderNavItem>
              </div>
            </SliderNav>
          )}
        </Tablet>
        <Mobile>
          <ProjectCoverMobile
            theme={coverThemeNames[coverTheme]}
            title={title.text}
            awards={awards.html}
            intro={intro.html}
            description={description.html}
            image={cover_photo}
          />
          <div
            className='pt-4 px-4 bg-gray-100'
            style={{ paddingBottom: '1px' }}
          >
            {body.map((slice, i) => {
              const { id, primary } = slice
              const {
                image,
                caption,
                descriptionButton,
                description,
                hasBorder,
              } = primary
              return (
                <StandaloneImage
                  key={id}
                  image={image}
                  onClick={() => this.showPswp(i)}
                  id={`projectSingleImage${i}`}
                  caption={caption.html}
                  descriptionButton={descriptionButton}
                  description={description.html}
                  hasBorder={hasBorder === 'Sí'}
                />
              )
            })}
          </div>
          <ProjectCoverMobile
            theme={nextProject.theme}
            title={nextProject.data.title.text}
            awards={
              nextProject.data.awards ? nextProject.data.awards.html : null
            }
            intro={nextProject.data.intro ? nextProject.data.intro.html : null}
            description={
              nextProject.data.description
                ? nextProject.data.description.html
                : null
            }
            image={nextProject.data.cover_photo}
            path={nextProject.fields.pagePath}
          />
        </Mobile>
        {pswpData && pswpData.length > 0 && (
          <Portal>
            <PhotoSwipe
              isOpen={pswpOpen}
              items={pswpData}
              onClose={this.hidePswp}
              options={{
                index: pswpIndex,
                showHideOpacity: true,
                history: false,
                fullscreenEl: true,
                zoomEl: false,
                shareEl: false,
                preloaderEl: false,
                barsSize: { top: 65, bottom: 60 },
                getThumbBoundsFn: index => {
                  const thumbnail = document.getElementById(
                    `projectSingleImage${index}`
                  )

                  if (thumbnail) {
                    if (this.swiper) this.swiper.slideTo(index + 1, 0)
                    const pageYScroll =
                      window.pageYOffset || document.documentElement.scrollTop
                    const rect = thumbnail.getBoundingClientRect()
                    return {
                      x: rect.left,
                      y: rect.top + pageYScroll,
                      w: rect.width,
                    }
                  }
                },
              }}
            />
          </Portal>
        )}
      </div>
    )
  }
}

Project.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  setHeaderTheme: PropTypes.func.isRequired,
  slideHasDescription: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTheme: theme => dispatch(setHeaderTheme(theme)),
    slideHasDescription: hasDescription =>
      dispatch(slideHasDescription(hasDescription)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(onPageRender(Project))

export const query = graphql`
  query($id: String!, $lang: String!) {
    project: prismicProject(id: { eq: $id }) {
      id
      data {
        ...ProjectDataFields
      }
      fields {
        pagePath
      }
    }
    projectsPage: prismicProjectsPage(lang: { eq: $lang }) {
      data {
        categories {
          ...ProjectsPageCategoryData
        }
        projects {
          ...ProjectsPageProjectData
        }
      }
    }
    homepage: prismicHomepage(lang: { eq: $lang }) {
      fields {
        featuredPrismicIds: featuredProjectIds
      }
    }
  }
`
