import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ShareIcon from '../ShareIcon/ShareIcon'
import styles from './ShareButton.module.scss'
import { setShareModalVisibility } from '../../actions/shareModalVisibility'
import { Translate } from 'react-localize-redux'

const colors = {
  label: {
    brown: 'text-gold-100',
    gold: 'text-brown-200',
    goldOnDark: 'text-gold-100',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
}

const classes = {
  button: [
    'lg:flex',
    'items-center',
    'relative',
    'focus:outline-none',
    //
  ].join(' '),
  label: [
    'hidden',
    'lg:inline-block',
    'ml-3',
    'leading-none',
    'font-sans-display',
    'text-sm',
    //
  ].join(' '),
}

const ShareButton = ({ theme, showModal }) => {
  return (
    <div>
      <button
        type='button'
        className={[classes.button, styles.button].join(' ')}
        onClick={() => showModal()}
      >
        <ShareIcon theme={theme} />
        <span
          className={[colors.label[theme], classes.label, styles.label].join(
            ' '
          )}
        >
          <Translate id='share' />
        </span>
      </button>
    </div>
  )
}

ShareButton.propTypes = {
  theme: PropTypes.string,
}

const mapDispatchToProps = dispatch => {
  return {
    showModal: () => dispatch(setShareModalVisibility(true)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ShareButton)
