import React from 'react'

const SliderNavIcon = () => {
  return (
    <svg
      fill='none'
      height='16'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g stroke='#9f4e53'>
        <path d='m.5.5h6v6h-6z' />
        <path d='m9.5.5h6v6h-6z' />
        <path d='m.5 9.5h6v6h-6z' />
        <path d='m9.5 9.5h6v6h-6z' />
      </g>
    </svg>
  )
}

export default SliderNavIcon
