import React from 'react'
import PropTypes from 'prop-types'

const colors = {
  brown: '#C9B480',
  gold: '#4A2F23',
  goldOnDark: '#C9B480',
  gray: '#903f44',
  grayDark: '#4A2F23',
}

const ShareIcon = ({ theme }) => {
  return (
    <svg
      fill='none'
      height='18'
      viewBox='0 0 14 18'
      width='14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill={colors[theme]}>
        <path d='m5.48594 3.0333.8167-1.51663v9.91663h1.4v-9.91663l.8167 1.51663 2.09996 2.2167 1.05-1.05-4.66666-4.2-4.6667 4.2 1.05 1.05z' />
        <path d='m14 7.35159h-1.5167v8.98331h-10.9666v-8.98331h-1.5167v10.50001h14z' />
      </g>
    </svg>
  )
}

ShareIcon.propTypes = {
  theme: PropTypes.string.isRequired,
}

export default ShareIcon
