import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from '../Img/Img'
import styles from './ProjectCoverMobile.module.scss'
import TabPanel from '../TabPanel/TabPanel'
import { Translate } from 'react-localize-redux'
import AniLink from '../AniLink/AniLink'
import ShareButton from '../ShareButton/ShareButton'

const colors = {
  info: {
    brown: 'bg-brown-300',
    gold: 'bg-gold-100',
    gray: 'bg-gray-200',
    grayDark: 'bg-gray-300',
  },
  titles: {
    brown: 'text-gold-100',
    gold: 'text-brown-200',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
  intro: {
    brown: 'text-gold-100',
    gold: 'text-brown-200',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
  description: {
    brown: 'text-gold-100',
    gold: 'text-brown-200',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
}

const classes = {
  image: [
    'pt-4',
    'px-4',
    //
  ].join(' '),
  info: [
    'p-4',
    'relative',
    //
  ].join(' '),
  next: [
    '-mb-2',
    'text-center',
    'font-sans-display',
    'opacity-60',
    //
  ].join(' '),
  titleContainer: [
    'py-10',
    //
  ].join(' '),
  title: [
    'font-serif-display',
    'leading-none',
    'tracking-tight',
    'text-4xl',
    'uppercase',
    'text-center',
    //
  ].join(' '),
  awards: [
    'mt-1',
    'px-2',
    'font-sans-display',
    'leading-tight',
    'tracking-tight',
    'text-center',
    //
  ].join(' '),
  continueContainer: [
    'text-center',
    //
  ].join(' '),
  continueLink: [
    'block',
    //
  ].join(' '),
  continueLinkText: [
    'inline-block',
    'font-sans-display',
    'uppercase',
    'tracking-wide',
    //
  ].join(' '),
  continueLinkArrow: [
    'inline-block',
    'font-serif-display',
    'text-lg',
    //
  ].join(' '),
  share: [
    'mt-6',
    //
  ].join(' '),
  descButtonContainer: [
    'relative',
    'text-center',
    //
  ].join(' '),
  descButton: [
    'p-2',
    'font-sans-display',
    'text-lg',
    'focus:outline-none',
    //
  ].join(' '),
  intro: [
    'pt-6',
    'font-serif-display',
    'leading-tight',
    'text-lg',
    //
  ].join(' '),
  description: [
    'pt-6',
    'leading-tight',
    'font-sans-display',
    //
  ].join(' '),
}

class ProjectCoverMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      descVisible: false,
    }
    this.toggleDescription = this.toggleDescription.bind(this)
  }

  toggleDescription() {
    this.setState({ descVisible: !this.state.descVisible })
  }

  render() {
    const { theme, title, awards, intro, description, image, path } = this.props
    const { descVisible } = this.state
    const hasIntro = intro && intro !== '' && intro !== '<p></p>'
    const hasDescription =
      description && description !== '' && description !== '<p></p>'
    return (
      <div>
        {!path && (
          <div
            className={[colors.info[theme], styles.image, classes.image].join(
              ' '
            )}
          >
            <Img data={image} />
          </div>
        )}
        <div className={[colors.info[theme], classes.info].join(' ')}>
          {path && (
            <div className={[classes.next, colors.titles[theme]].join(' ')}>
              <span>
                <Translate id='next' />
              </span>
            </div>
          )}
          <div
            className={[classes.titleContainer, path ? 'my-4' : ''].join(' ')}
          >
            <div className={[colors.titles[theme], classes.title].join(' ')}>
              {title}
            </div>
            {awards && (
              <div
                dangerouslySetInnerHTML={{
                  __html: awards,
                }}
                className={[colors.titles[theme], classes.awards].join(' ')}
              />
            )}
          </div>
          {path && (
            <div className={classes.continueContainer}>
              <AniLink
                to={path}
                className={[
                  colors.titles[theme],
                  classes.continueLink,
                  styles.continueLink,
                  awards ? 'mt-6' : '',
                ].join(' ')}
              >
                <span
                  className={[
                    classes.continueLinkText,
                    styles.continueLinkText,
                  ].join(' ')}
                >
                  <Translate id='continue' />
                </span>
                <span
                  className={[
                    classes.continueLinkArrow,
                    styles.continueLinkArrow,
                  ].join(' ')}
                >
                  →
                </span>
              </AniLink>
            </div>
          )}
          {(hasIntro || hasDescription) && !path ? (
            <>
              <div className={classes.descButtonContainer}>
                <button
                  type='button'
                  onClick={this.toggleDescription}
                  className={[
                    colors.titles[theme],
                    classes.descButton,
                    styles.descButton,
                    descVisible ? 'opacity-50' : '',
                  ].join(' ')}
                >
                  <span>
                    <Translate id='description' />
                  </span>
                </button>
              </div>
              <TabPanel visible={descVisible}>
                <div className='px-2 pb-4'>
                  {hasIntro && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intro,
                      }}
                      className={[
                        colors.titles[theme],
                        classes.intro,
                        styles.intro,
                      ].join(' ')}
                    />
                  )}
                  {hasDescription && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                      className={[
                        colors.titles[theme],
                        classes.description,
                        styles.description,
                      ].join(' ')}
                    />
                  )}
                  {!path && (
                    <div className={classes.share}>
                      <ShareButton theme={theme} />
                    </div>
                  )}
                </div>
              </TabPanel>
            </>
          ) : null}
        </div>
      </div>
    )
  }
}

ProjectCoverMobile.propTypes = {
  theme: PropTypes.oneOf(['brown', 'gold', 'gray', 'grayDark']),
  title: PropTypes.string,
  awards: PropTypes.string,
  intro: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  path: PropTypes.string,
}

export default ProjectCoverMobile
