import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './FadeInOnMount.module.scss'

class FadeInOnMount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  componentDidMount() {
    this.setState({ visible: true })
  }

  render() {
    const { children, delay, duration, timingFunction } = this.props
    const { visible } = this.state
    return (
      <div
        className={`${styles.el} ${visible ? styles.visible : ''}`}
        style={{
          transitionDuration: `${duration}ms`,
          transitionDelay: `${delay}ms`,
          transitionTimingFunction: timingFunction,
        }}
      >
        {children}
      </div>
    )
  }
}

FadeInOnMount.propTypes = {
  children: PropTypes.any,
  delay: PropTypes.number,
  timingFunction: PropTypes.string,
}

FadeInOnMount.defaultProps = {
  delay: 600,
  duration: 800,
  timingFunction: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
}

export default FadeInOnMount
