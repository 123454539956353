import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import { Mousewheel } from 'swiper/dist/js/swiper.esm.js'
import './swiper.scss'
import styles from './Slider.module.scss'

const swiperParams = {
  modules: [Mousewheel],
  slidesPerView: 1,
  speed: 600,
  height:
    typeof document !== 'undefined' ? document.documentElement.clientHeight : 0,
  autoHeight: true,
  resistance: false,
  shouldSwiperUpdate: true,
  rebuildOnUpdate: true,
  mousewheel: true,
}

class Slider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 'auto',
      height: 'auto',
    }
    this.setSize = this.setSize.bind(this)
    this.getSwiperInstances = this.getSwiperInstances.bind(this)
  }

  getSwiperInstances(swiper) {
    this.getSwiper(swiper)

    const { getSwiper } = this.props

    if (typeof getSwiper === 'function') getSwiper(swiper)
  }

  getSwiper(swiper) {
    this.swiper = swiper
  }

  componentDidMount() {
    this.setSize()
    window.addEventListener('resize', this.setSize)

    if (this.swiper) {
      this.swiper.mousewheel.enable()
      this.swiper.allowTouchMove = true
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize)
  }

  setSize() {
    const width = `${document.documentElement.clientWidth}px`
    const height = `${document.documentElement.clientHeight}px`
    this.setState({ width, height })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mousewheel !== this.props.mousewheel && this.swiper) {
      if (this.props.mousewheel) {
        this.swiper.mousewheel.enable()
        this.swiper.allowTouchMove = true
      } else {
        this.swiper.mousewheel.disable()
        this.swiper.allowTouchMove = false
      }
    }
  }

  render() {
    const { children, direction } = this.props
    const { width, height } = this.state
    return (
      <div className={styles.wrap} style={{ width, height }}>
        <Swiper
          getSwiper={this.getSwiperInstances}
          {...swiperParams}
          direction={direction}
        >
          {children}
        </Swiper>
      </div>
    )
  }
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  getSwiper: PropTypes.func,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
}

Slider.defaultProps = {
  direction: 'horizontal',
  mousewheel: true,
}

export default Slider
