import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fitty from 'fitty'
import { watchViewport, unwatchViewport } from 'tornis'
import Img from '../Img/Img'
import FadeInOnMount from '../FadeInOnMount/FadeInOnMount'
import AniLink from '../AniLink/AniLink'
import styles from './ProjectCover.module.scss'
import { Translate } from 'react-localize-redux'
import ShareButton from '../ShareButton/ShareButton'

const colors = {
  background: {
    left: {
      brown: 'bg-gray-100',
      gold: 'bg-gray-100',
      gray: 'bg-gray-100',
      grayDark: 'bg-gray-100',
    },
    right: {
      brown: 'bg-brown-300',
      gold: 'bg-gold-100',
      gray: 'bg-gray-200',
      grayDark: 'bg-gray-300',
    },
  },
  titles: {
    brown: 'text-gold-100',
    gold: 'text-brown-200',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
  intro: {
    brown: 'text-gold-100',
    gold: 'text-brown-200',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
  description: {
    brown: 'text-gold-100',
    gold: 'text-brown-200',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
  copyright: {
    brown: 'text-brown-200',
    gold: 'text-brown-200',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
}

const classes = {
  image: [
    'absolute',
    'mx-6',
    //
  ].join(' '),
  textContainer: [
    'absolute',
    'inset-0',
    'px-6',
    'py-6',
    'lg:px-10',
    'xl:px-16',
    'lg:pb-16',
    'flex',
    'flex-col',
    'text-center',
    'lg:text-left',
    //
  ].join(' '),
  title: [
    'font-serif-display',
    'uppercase',
    'leading-none',
    'tracking-tight',
  ].join(' '),
  awards: [
    'font-serif-display',
    'text-2xl',
    'xl:text-3xl',
    'leading-none',
    'tracking-tight',
    //
  ].join(' '),
  intro: [
    'mb-6',
    'mr-auto',
    'ml-auto',
    'lg:mr-0',
    'lg:ml-0',
    'xl:mb-8',
    'lg:pt-3',
    'font-sans-display',
    'leading-tight',
    'text-lg',
    'xl:text-xl',
    '2xl:text-2xl',
    //
  ].join(' '),
  description: [
    'mr-auto',
    'ml-auto',
    'lg:mr-0',
    'lg:ml-0',
    'font-sans-display',
    'text-sm',
    'xl:text-base',
    'leading-snug',
    'clearfix',
    //
  ].join(' '),
  share: [
    'absolute',
    'bottom-0',
    'left-0',
    'mb-6',
    'ml-6',
    'lg:ml-10',
    'lg:mb-10',
    'xl:ml-16',
    'z-50',
    //
  ].join(' '),
  copyright: [
    'hidden',
    'lg:block',
    'absolute',
    'left-0',
    'bottom-0',
    'ml-6',
    'mb-6',
    'font-sans-display',
    'leading-none',
    'text-sm',
  ].join(' '),
  prevSlideButton: [
    'hidden',
    'lg:block',
    'absolute',
    'top-0',
    'left-0',
    'bottom-0',
    'w-1/2',
    'z-40',
    //
  ].join(' '),
  nextSlideButton: [
    'absolute',
    'top-0',
    'right-0',
    'bottom-0',
    'left-0',
    'lg:left-auto',
    'lg:w-1/2',
    'z-40',
    //
  ].join(' '),
  continueContainer: [
    'lg:hidden',
    'absolute',
    'right-0',
    'bottom-0',
    'left-0',
    'mb-6',
    'text-center',
    //
  ].join(' '),
  continueLink: [
    'block',
    //
  ].join(' '),
  continueLinkText: [
    'inline-block',
    'font-sans-display',
    'uppercase',
    'tracking-wide',
    //
  ].join(' '),
  continueLinkArrow: [
    'inline-block',
    'font-serif-display',
    'text-lg',
    //
  ].join(' '),
}

class ProjectCover extends Component {
  constructor(props) {
    super(props)
    this.fitTitle = this.fitTitle.bind(this)
  }

  componentDidMount() {
    watchViewport(this.fitTitle)
  }

  componentWillUnmount() {
    unwatchViewport(this.fitTitle)
  }

  fitTitle({ size }) {
    if (!this.title) return

    if (size.changed) {
      const { x } = size
      const getMinSize = function() {
        if (x < 1024) {
          return 36
        } else if (x >= 1024 && x < 1440) {
          return 48
        } else if (x >= 1440) {
          return 72
        }
      }
      const getMaxSize = function() {
        if (x < 1024) {
          return 80
        } else {
          return 130
        }
      }
      const { title } = this.props
      const minSize = title.length >= 16 ? getMinSize() : null
      fitty(this.title, { minSize, maxSize: getMaxSize() })
    }
  }

  render() {
    const {
      theme,
      title,
      awards,
      intro,
      description,
      image,
      prevPath,
      path,
      onClickPrev,
      onClickNext,
      atHomepage,
    } = this.props
    const centerTitle = function() {
      if (
        intro &&
        intro.length < 200 &&
        (description === '<p></p>' || description === '')
      ) {
        return true
      }
      if (
        (intro === '<p></p>' || intro === '') &&
        description &&
        description.length < 200
      ) {
        return true
      }
      if (
        (intro === '<p></p>' || intro === '') &&
        (description === '<p></p>' || description === '')
      ) {
        return true
      }
      return false
    }
    const centerDescriptions = function() {
      if (
        intro &&
        intro.length < 200 &&
        (description === '<p></p>' || description === '')
      ) {
        return true
      }
      if (
        (intro === '<p></p>' || intro === '') &&
        description &&
        description.length < 200
      ) {
        return true
      }
      return false
    }
    return (
      <div className='absolute inset-0 lg:flex'>
        <div
          className={[
            styles.column,
            colors.background.left[theme],
            'relative',
          ].join(' ')}
        >
          <FadeInOnMount duration={900} delay={0}>
            <div className={`${styles.image} ${classes.image}`}>
              <Img data={image} className='object-contain object-top' />
            </div>
          </FadeInOnMount>
        </div>
        <div
          className={`${styles.column} ${colors.background.right[theme]} relative`}
        >
          <div className={classes.textContainer}>
            <div className={centerTitle() ? 'mt-auto' : 'mt-auto lg:mt-0'}>
              <FadeInOnMount duration={900} delay={300}>
                <div className={styles.titleContainer}>
                  <div
                    ref={el => (this.title = el)}
                    className={[
                      classes.title,
                      styles.title,
                      intro || description ? styles.titleMargin : '',
                      colors.titles[theme],
                    ].join(' ')}
                  >
                    <span>{title}</span>
                  </div>
                </div>
              </FadeInOnMount>
            </div>
            {awards && (
              <FadeInOnMount delay={500}>
                <div
                  className={[
                    classes.awards,
                    styles.awards,
                    colors.titles[theme],
                  ].join(' ')}
                  dangerouslySetInnerHTML={{
                    __html: awards,
                  }}
                />
              </FadeInOnMount>
            )}
            <div
              className={[
                centerDescriptions() ? '' : 'lg:mt-auto mb-auto',
                centerDescriptions() ? 'mb-auto lg:pb-20' : styles.infoMargin,
              ].join(' ')}
            >
              {intro || description ? (
                <FadeInOnMount delay={600}>
                  {intro && intro !== '<p></p>' && (
                    <div
                      className={[
                        classes.intro,
                        styles.intro,
                        colors.intro[theme],
                      ].join(' ')}
                      dangerouslySetInnerHTML={{
                        __html: intro,
                      }}
                    />
                  )}
                  {description && description !== '<p></p>' && (
                    <div
                      className={[
                        classes.description,
                        styles.description,
                        colors.description[theme],
                      ].join(' ')}
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  )}
                </FadeInOnMount>
              ) : null}
            </div>
          </div>
          {!path && (
            <div className={classes.share}>
              <ShareButton theme={theme} />
            </div>
          )}
        </div>
        <div className={[classes.copyright, colors.copyright[theme]].join(' ')}>
          Sandra Gross © 2019
        </div>
        {prevPath && (
          <AniLink
            to={prevPath}
            className={[classes.prevSlideButton, styles.prevSlideButton].join(
              ' '
            )}
            state={{ disableAnimation: false, atHomepage }}
          />
        )}
        {path ? (
          <>
            {onClickPrev && (
              <div
                role='button'
                className={[
                  classes.prevSlideButton,
                  styles.prevSlideButton,
                ].join(' ')}
                onClick={() => {
                  if (typeof onClickPrev === 'function') {
                    onClickPrev()
                  }
                }}
              />
            )}
            <AniLink
              to={path}
              className={[classes.nextSlideButton, styles.nextSlideButton].join(
                ' '
              )}
              state={{ disableAnimation: false, skipCover: true, atHomepage }}
            />
          </>
        ) : (
          <div
            role='button'
            className={[classes.nextSlideButton, styles.nextSlideButton].join(
              ' '
            )}
            onClick={() => {
              if (typeof onClickNext === 'function') {
                onClickNext()
              }
            }}
          />
        )}
        <div className={classes.continueContainer}>
          <div
            to={path}
            className={[
              colors.titles[theme],
              classes.continueLink,
              styles.continueLink,
              awards ? 'mt-6' : '',
            ].join(' ')}
          >
            <span
              className={[
                classes.continueLinkText,
                styles.continueLinkText,
              ].join(' ')}
            >
              <Translate id='continue' />
            </span>
            <span
              className={[
                classes.continueLinkArrow,
                styles.continueLinkArrow,
              ].join(' ')}
            >
              →
            </span>
          </div>
        </div>
      </div>
    )
  }
}

ProjectCover.propTypes = {
  theme: PropTypes.oneOf(['brown', 'gold', 'gray', 'grayDark']),
  title: PropTypes.string,
  awards: PropTypes.string,
  intro: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  prevPath: PropTypes.string,
  path: PropTypes.string,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
  atHomepage: PropTypes.bool,
}

export default ProjectCover
