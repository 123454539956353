import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { watchViewport, unwatchViewport } from 'tornis'
import Img from '../Img/Img'
import ProjectImageDescription from '../ProjectImageDescription/ProjectImageDescription'
import styles from './ProjectSingleImage.module.scss'

const SUM_OF_Y_MARGINS = 260

const classes = {
  caption: [
    'pt-1',
    'font-sans-display',
    'absolute',
    'right-0',
    'bottom-0',
    'left-0',
    'leading-none',
    'text-sm',
    'text-red-100',
  ].join(' '),
  prevButton: [
    'left-0',
    //
  ].join(' '),
  nextButton: [
    'right-0',
    //
  ].join(' '),
}

class ProjectSingleImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 'auto',
      height: 'auto',
    }
    this.setSize = this.setSize.bind(this)
  }

  componentDidMount() {
    watchViewport(this.setSize)
  }

  componentWillUnmount() {
    unwatchViewport(this.setSize)
  }

  setSize({ size }) {
    if (size.changed) {
      const { x, y } = size
      const { image } = this.props
      const { dimensions } = image
      const imageWidth = dimensions.width
      const imageHeight = dimensions.height
      const height = y - SUM_OF_Y_MARGINS
      const pct = (height / imageHeight) * 100
      const width = (imageWidth * pct) / 100 - (x < 1024 ? 100 : 0)
      let widthValue = width
      let heightValue = height

      if (x <= width) {
        const wPct = (x / width) * 100
        const smallWidth = x - 100
        const smallHeight = (height * wPct) / 100 - 100
        widthValue = smallWidth
        heightValue = smallHeight
      }

      const prevNextButtonWidth = `${(x - widthValue) / 2}px`
      this.setState({
        width: `${widthValue}px`,
        height: `${heightValue}px`,
        prevNextButtonWidth,
      })
    }
  }

  render() {
    const {
      image,
      caption,
      descriptionButton,
      description,
      theme,
      active,
      onClick,
      onClickPrev,
      onClickNext,
      id,
      hasBorder,
    } = this.props
    const { width, height, prevNextButtonWidth } = this.state
    return (
      <div
        className='absolute inset-0 bg-gray-200__ overflow-hidden'
        style={{
          backgroundColor: '#dedede',
        }}
      >
        <div
          role='button'
          className={[styles.frame, hasBorder ? styles.hasBorder : ''].join(
            ' '
          )}
          style={{
            width,
            height,
            left: prevNextButtonWidth,
          }}
          onClick={() => {
            if (typeof onClick === 'function') {
              onClick()
            }
          }}
        >
          <Img data={image} className='cursor-pointer' id={id} />
          <div
            className={[
              classes.caption,
              styles.caption,
              active ? styles.visible : '',
            ].join(' ')}
            dangerouslySetInnerHTML={{
              __html: caption,
            }}
          />
        </div>
        <div
          role='button'
          className={[
            styles.prevNextButton,
            styles.prevButton,
            classes.prevButton,
          ].join(' ')}
          style={{ width: prevNextButtonWidth }}
          onClick={() => {
            if (typeof onClickPrev === 'function') {
              onClickPrev()
            }
          }}
        />
        <div
          role='button'
          className={[
            styles.prevNextButton,
            styles.nextButton,
            classes.nextButton,
          ].join(' ')}
          style={{ width: prevNextButtonWidth }}
          onClick={() => {
            if (typeof onClickNext === 'function') {
              onClickNext()
            }
          }}
        />
        {description && (
          <ProjectImageDescription
            active={active}
            buttonText={descriptionButton}
            html={description}
            theme={theme}
          />
        )}
      </div>
    )
  }
}

ProjectSingleImage.propTypes = {
  caption: PropTypes.string,
  descriptionButton: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
  id: PropTypes.string,
  hasBorder: PropTypes.bool,
}

export default ProjectSingleImage
