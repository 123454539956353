import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './SliderPrevNext.module.scss'

const classes = {
  container: [
    'absolute',
    'right-0',
    'bottom-0',
    'left-0',
    'z-10',
    'flex',
    'items-baseline',
    'p-6',
  ].join(' '),
  button: [
    'font-serif-display',
    'text-2xl',
    'leading-none',
    'focus:outline-none',
    'text-red-100',
  ].join(' '),
  counter: [
    'mr-4',
    'font-sans-display',
    'font-light',
    'text-3xl',
    'text-red-100',
  ].join(' '),
}

const SliderPrevNext = ({
  visible,
  total,
  activeIndex,
  swiperInstance,
  slideHasDescription,
}) => {
  const activeIndexText =
    activeIndex === 0 ? 1 : activeIndex <= total ? activeIndex : total
  return (
    <div
      className={[
        styles.container,
        classes.container,
        visible ? styles.visible : '',
        slideHasDescription ? styles.slideHasDescription : '',
      ].join(' ')}
    >
      <div className='mr-auto'>
        <button
          className={classes.button}
          onClick={() => swiperInstance.slidePrev()}
        >
          ←
        </button>
      </div>
      <div className={`${classes.counter} ${styles.counter}`}>
        {activeIndexText}/{total}
      </div>
      <div>
        <button
          className={classes.button}
          onClick={() => swiperInstance.slideNext()}
        >
          →
        </button>
      </div>
    </div>
  )
}

SliderPrevNext.propTypes = {
  visible: PropTypes.bool.isRequired,
  swiperInstance: PropTypes.object.isRequired,
  total: PropTypes.number,
  activeIndex: PropTypes.number,
  slideHasDescription: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ slideHasDescription }) => {
  return { slideHasDescription }
}

export default connect(mapStateToProps)(SliderPrevNext)
