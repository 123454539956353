import React from 'react'

const SmallZoomInIcon = () => {
  return (
    <svg
      fill='none'
      height='18'
      viewBox='0 0 19 18'
      width='19'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g stroke='#9f4e53'>
        <circle cx='11.9445' cy='6.3' r='5.8' />
        <path d='m7.64453 11-6.64264 6.3536' strokeWidth='1.15' />
      </g>
    </svg>
  )
}

export default SmallZoomInIcon
