import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { watchViewport, unwatchViewport } from 'tornis'
import styles from './Slide.module.scss'

class Slide extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 'auto',
      height: 'auto',
    }
    this.setSize = this.setSize.bind(this)
  }

  componentDidMount() {
    this.setSize()
    window.addEventListener('resize', this.setSize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize)
  }

  setSize() {
    const width = `${document.documentElement.clientWidth}px`
    const height = `${document.documentElement.clientHeight}px`
    this.setState({ width, height })
  }

  /*
  componentDidMount() {
    watchViewport(this.setSize)
  }

  componentWillUnmount() {
    unwatchViewport(this.setSize)
  }

  setSize({ size }) {
    if (size.changed) {
      const { x, y } = size
      this.setState({
        width: `${x}px`,
        height: `${y}px`,
      })
    }
  }
  */

  render() {
    const { children, index } = this.props
    const { width, height } = this.state
    return (
      <div
        style={{ width, height }}
        className={`relative ${styles.slide}`}
        index={index}
      >
        {children}
      </div>
    )
  }
}

Slide.propTypes = {
  index: PropTypes.number,
  children: PropTypes.element,
}

export default Slide
